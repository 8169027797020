import React from "react"
import PropType from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { isMobile } from "react-device-detect"
import LazyLoad from "react-lazyload"
import CssGrid from "../components/CssGrid.tsx"
import BookingWidget from "../components/BookingWidget.tsx"
import { Carousel } from "../components/Carousel"
import Content from "../components/Content.tsx"
import Grid from "react-css-grid"
import { Hero } from "../components/Hero"
import ImageCarousel from "../components/ImageCarousel.tsx"
import { Logo } from "../components/Logo"
import MediaCentre from "../components/MediaCentre.tsx"
import SEO from "../components/Seo.js"
import Layout from "../components/layout"

import { BOOKING_CONFIG } from "../constants"

let mobilecontent
let LOGO_SRC
let CAROUSEL_ITEMS = {}
let PROPRIETIES_CAROUSEL_ITEMS = {}
let MEDIA_CENTRE = []
let HERO = []
// const stripHtml = (html) => {
//   if (typeof window !== 'undefined') {
//     const doc = new DOMParser().parseFromString(html, 'text/html');
//     return doc.body.textContent || '';
//   }
//   return html;
// };

const PostTemplate = props => {
  const {
    data: {
      wordpressPage: post,
      allWordpressAcfOptions: acf,
      allFeedOvoloHotels: mediacentre,
    },
  } = props

  if (isMobile) {
    var mobilecontent = post.content.replace(
      'class="content ',
      'class="content mobile '
    )
  } else {
    var mobilecontent = post.content
  }

  acf.edges.map(({ node }) => (LOGO_SRC = node.options.logo.source_url))
  acf.edges.map(({ node }, i) => (CAROUSEL_ITEMS = node.options.home_carousel))
  acf.edges.map(
    ({ node }, i) => (PROPRIETIES_CAROUSEL_ITEMS = node.options.proprieties)
  )

  return (
    <Layout location={props.location}>
      <>
        <Helmet link={[{ rel: "canonical", content: post.yoast.canonical }]} />
        <SEO
          title={post.yoast.title || post.acf.title_seo || post.title}
          description={
            post.yoast.metadesc || post.acf.paragraph_seo || post.excerpt
          }
          pathname={post.slug}
        />
        <Hero>
          <Carousel items={CAROUSEL_ITEMS} interval={7000}>
            <Logo
              link={{ href: "/", alt: "Ovolo Group" }}
              alt="Ovolo Group"
              src={LOGO_SRC}
              color="white"
            />
            <BookingWidget
              hotelsConfig={BOOKING_CONFIG}
              title=" "
              arriveLabel="Arrive"
              departLabel="Departure"
              guestLabel="Guest"
              hideMobile
            />
          </Carousel>
        </Hero>
      </>

      <LazyLoad height={"100%"}>
        <Content background="dark" pcarousel>
          <ImageCarousel
            slidesPerPage={6}
            slidesPerScroll={1}
            items={PROPRIETIES_CAROUSEL_ITEMS}
            offset={20}
          />
          <div className="scrollForMore">
            <h5>Scroll for more</h5>
          </div>
        </Content>
      </LazyLoad>

      <div
        className="landing-columns"
        dangerouslySetInnerHTML={{ __html: mobilecontent }}
      />

      {mediacentre.edges.map(({ node }, i) => {
        MEDIA_CENTRE.push(node)
      })}
      <LazyLoad height={"100%"}>
        <MediaCentre items={MEDIA_CENTRE} />
      </LazyLoad>
    </Layout>
  )
}
PostTemplate.propTypes = {
  data: PropType.shape({}).isRequired,
}
export default PostTemplate

export const pageQuery = graphql`
  query {
    wordpressPage(wordpress_id: { eq: 6 }) {
      title
      content
      excerpt
      slug
      yoast {
        title
        metadesc
        canonical
      }
      acf {
        title_seo
        subtitle_seo
        paragraph_seo
      }
    }
    allWordpressAcfOptions {
      edges {
        node {
          options {
            logo {
              source_url
            }
            proprieties {
              title
              link
              brand
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 237, maxHeight: 237) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            home_carousel {
              title
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1280) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allFeedOvoloHotels(limit: 4) {
      edges {
        node {
          id
          title
          link
          contentSnippet
          pubDate
          thumbnail {
            url
          }
        }
      }
    }
  }
`
