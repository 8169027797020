import React, { FunctionComponent } from "react"

export const Address: FunctionComponent<{ value: string }> = ({ value }) => (
  <div className="address">
    <h3>Address:</h3>

    <p>{value}</p>
  </div>
)

export default Address
