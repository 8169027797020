import React, { FunctionComponent } from "react"
import { Button } from "./Button"
import { IKeyPar } from "../types"

export const Newsletter: FunctionComponent<{
  postUrl: string
  title: string
  config: IKeyPar[]
}> = ({ postUrl, config, title }) => (
  <div className="newsletter">
    <h3>{title}</h3>
    <input
      className="btn full"
      type="submit"
      name="Submit"
      value="Subscribe"
      onClick={() => window.open(postUrl, "_blank")}
    />
  </div>
)

export default Newsletter
