import React, { FunctionComponent, useState, useEffect } from "react"
import { IHyperlink } from "../types"

export const ContentImage: FunctionComponent<{
  image: string
  link?: IHyperlink
  title: string
}> = ({ image, link, title }) => {
  const [imageSrc, setImageSrc] = useState(image)
  useEffect(() => {
    image.indexOf("http") === -1 &&
      import(`../images/content/${image}`).then(module => {
        setImageSrc(module.default)
      })
  })
  return (
    <>
      {link && (
        <a href={link.href} target={link.target}>
          <img src={imageSrc} alt={title} />
        </a>
      )}
      {!link && <img src={imageSrc} alt={title} />}
    </>
  )
}

export default ContentImage
