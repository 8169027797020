import React, { FunctionComponent } from "react"

export const Telephone: FunctionComponent<{ value: string }> = ({ value }) => (
  <div className="telephone">
    <h3>Telephone:</h3>

    <p>{value}</p>
  </div>
)

export default Telephone
