import React, { FunctionComponent, useState } from "react"
import Carousel, { Dots } from "@brainhubeu/react-carousel"
import SimpleModal from "./SimpleModal"

import "./ImageCarousel.scss"

import "@brainhubeu/react-carousel/lib/style.css"
import { isMobileOnly, isTablet } from "react-device-detect"
import classNames from "classnames"
import { ICarouselItem } from "../types"

export const ImageCarousel: FunctionComponent<{
  items: ICarouselItem[]
  bullets?: boolean
  slidesPerPage?: number
  slidesPerScroll?: number
  itemWidth?: number
  offset?: number
  light?: boolean
}> = ({
  items,
  bullets,
  slidesPerPage = 3,
  slidesPerScroll = 3,
  itemWidth = 200,
  offset = 20,
  light,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [imgSrc, setImgSrc] = useState<string>("")
  const [currentSlide, setCurrentSlide] = useState(0)

  const handleOpenModal = (src: string): void => {
    setShowModal(true)
    setImgSrc(src)
  }
  return (
    <div
      className={classNames("image-carousel", {
        mobile: isMobileOnly,
        tablet: isTablet,
        light: light,
      })}
    >
      <Carousel
        breakpoints={{
          600: {
            slidesPerPage: 1,
            slidesPerScroll: 1,
            centered: true,
            itemWidth: 100,
          },
          1000: {
            slidesPerPage: 2,
            slidesPerScroll: 1,
            itemWidth: 237,
          },
          1300: {
            slidesPerPage: 3,
            slidesPerScroll: 1,
          },
          1500: {
            slidesPerPage: 4,
            slidesPerScroll: 1,
          },
        }}
        slidesPerPage={slidesPerPage}
        slidesPerScroll={slidesPerScroll}
        offset={offset}
        arrows
        infinite
        value={currentSlide}
        onChange={setCurrentSlide}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className="item"
            style={{
              backgroundImage: `url("${
                item.image.localFile.childImageSharp.fluid.src
              }")`,
            }}
            onClick={() => {
              item.link
                ? (window.location.href = item.link)
                : handleOpenModal(item.image)
            }}
          />
        ))}
      </Carousel>
      {bullets && (
        <Dots
          value={currentSlide}
          onChange={setCurrentSlide}
          number={items.length}
        />
      )}
      <SimpleModal
        open={showModal}
        onClose={() => {
          setShowModal(false)
        }}
        containerStyle={{
          background: "none",
          maxWidth: "80rem",
          width: "auto",
        }}
        buttonStyle={{
          color: "var(--color-white)",
          position: "relative",
          left: "0.5rem",
        }}
      >
        <img src={imgSrc} />
      </SimpleModal>
    </div>
  )
}

export default ImageCarousel
