import React, { FunctionComponent, useState, ReactElement } from "react"

import "./Header.scss"
import { document } from "browser-monads"
import { isMobile } from "react-device-detect"
import classNames from "classnames"

// TODO: this component could useContext and useReducer instead of passing callbacks on props
export const Header: FunctionComponent<{ fixed?: boolean }> = ({
  children,
  fixed,
}) => {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false)
  const body = document.querySelector("body")

  const handleToggleNavigation = () => {
    isMobile && body && body.classList.toggle("open")
    if (isNavigationOpen) setTimeout(() => setIsNavigationOpen(false), 200)
    else setIsNavigationOpen(true)
  }

  const modifiedChildren = React.Children.map(children, child => {
    return React.cloneElement(child as ReactElement<any>, {
      handleToggleNavigation,
      isNavigationOpen,
    })
  })
  return (
    <header className={classNames({ fixed, isMobile })}>
      {modifiedChildren}
    </header>
  )
}

export default Header
