import React, { FunctionComponent, useState, useEffect } from "react"
import { useInterval } from "../hooks"

import "./Carousel.scss"
import { isMobile } from "react-device-detect"
import classNames from "classnames"
import { ICarouselItem } from "../types"
import Chevron from "../icons/icon-chevron-left.svg"

const CarouselImage: FunctionComponent<{
  item: ICarouselItem
  current: number
}> = ({ item, current }) => {
  const [imageSrc, setImageSrc] = useState(
    item.image.localFile.childImageSharp.fluid.src
  )
  useEffect(() => {
    item.image.localFile.childImageSharp.fluid.src.indexOf("http") === -1
  }, [item.image.localFile.childImageSharp.fluid.src])
  return (
    <div
      className="item"
      style={{
        backgroundImage: `url(${imageSrc})`,
        left: `-${current * 100}%`,
      }}
    >
      <div className="caption">
        <strong>{item.title.split(",")[0]},&nbsp;</strong>{" "}
        {item.title.split(",")[1]}
      </div>
    </div>
  )
}
export const Carousel: FunctionComponent<{
  items: ICarouselItem[]
  interval: number
}> = ({ items, interval, children }) => {
  const [active, setActive] = useState<number>(0)

  useInterval(() => setActive((active + 1) % items.length), interval, active)
  return (
    <div className={classNames("carousel", { mobile: isMobile })}>
      <div
        className="navigation left"
        onClick={() => setActive(active === 0 ? items.length - 1 : active - 1)}
      >
        <Chevron />
      </div>
      <div
        className="navigation right"
        onClick={() => setActive((active + 1) % items.length)}
      >
        <Chevron />
      </div>
      {children}
      <nav className="items">
        {items.map((item, index) => (
          <CarouselImage item={item} key={index} current={active} />
        ))}
      </nav>
      <nav className="bullets">
        {items.map((item, index) => (
          <span
            key={index}
            className={classNames({ active: active === index })}
            onClick={() => setActive(index)}
          />
        ))}
      </nav>
    </div>
  )
}

export default Carousel
