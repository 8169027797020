import React, { FunctionComponent, Fragment, useEffect, useRef } from "react"
import { isMobile } from "react-device-detect"
import CloseIcon from "../icons/icon-delete.svg"
import "./Navigation.scss"
import { INavigationItem } from "../types"
import classNames from "classnames"

import LogoMojo from "../icons/logo-mojo-white.svg"
import LogoOvolo from "../icons/logo-ovolo-white.svg"
import LogoCollective from "../icons/logo-collective-white.svg"

interface INavigationProps {
  items: INavigationItem[]
  isNavigationOpen?: boolean
  handleToggleNavigation?: () => void
}

let isSmall = isMobile
const hasWindow = typeof window !== `undefined` ? true : null
if (hasWindow) {
  if (window.innerWidth < 980) {
    isSmall = true
  }
}

export const Navigation: FunctionComponent<INavigationProps> = ({
  items,
  isNavigationOpen = false,
  handleToggleNavigation,
}) => {
  const node = useRef<HTMLElement>(null)

  const handleClick = (e: any) => {
    if (node.current && node.current.contains(e.target)) {
      return
    }
    handleToggleNavigation && handleToggleNavigation()
  }

  useEffect(() => {
    isNavigationOpen && document.addEventListener("touchstart", handleClick)

    return () => {
      document.removeEventListener("touchstart", handleClick)
    }
  }, [items])

  return !isSmall || isNavigationOpen ? (
    <nav
      itemScope={true}
      ref={node}
      className={classNames({ mobile: isSmall, open: isNavigationOpen })}
      itemType="https://schema.org/SiteNavigationElement"
    >
      {isSmall && (
        <CloseIcon
          className="close-icon"
          onClick={() => handleToggleNavigation && handleToggleNavigation()}
        />
      )}
      {isSmall && (
        <dl className="mobile">
          {items.map((item, index) => (
            <Fragment key={index}>
              <dt>
                <a target={item.target} href={item.url}>
                  {item.title}
                </a>
              </dt>
              {item.wordpress_children &&
                item.wordpress_children.map((subitem, subindex) => (
                  <dd key={subindex}>
                    <a target={subitem.target} href={subitem.url}>
                      {subitem.title}
                    </a>
                  </dd>
                ))}
            </Fragment>
          ))}
        </dl>
      )}
      {!isSmall && (
        <ul className="not-mobile">
          {items.map((item, index) => {
            return (
              <Fragment key={index}>
                <li
                  className={classNames({
                    ["dropdown-background"]:
                      item.wordpress_children && !item.image,
                  })}
                >
                  <a target={item.target} href={item.url}>
                    {item.title === "Ovolo Hotels" ? (
                      <LogoOvolo />
                    ) : item.title === "Ovolo Collective" ? (
                      <LogoCollective />
                    ) : (
                      item.title
                    )}
                  </a>

                  {item.wordpress_children && (
                    <div className="dropdown-wrapper">
                      <ul className="dropdown">
                        {item.wordpress_children.map((subitem, index) => (
                          <li key={index}>
                            <a href={subitem.url} target={subitem.target}>
                              {subitem.title.split("(")[0]}
                              {subitem.title.split("(")[1] && (
                                <span>{`(${subitem.title.split("(")[1]}`}</span>
                              )}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              </Fragment>
            )
          })}
        </ul>
      )}
    </nav>
  ) : (
    <></>
  )
}
export default Navigation
