import React, { FunctionComponent, useEffect, useState } from "react"
import LogoBlack from "../icons/logo-ovolo-black.svg"
import LogoWhite from "../icons/logo-ovolo-white.svg"
import LogoGroup from "../icons/logo-ovolo-group.svg"

import "./Logo.scss"
import { IAward, IHyperlink } from "../types"
import { isMobile } from "react-device-detect"

interface ILogoProps {
  alt: string
  src?: string
  color?: "white" | "black"
  award?: IAward
  link?: IHyperlink
}

const AwardImage: FunctionComponent<{ award: IAward }> = ({ award }) => {
  const [imageSrc, setImageSrc] = useState("//placehold.it/55x51")

  useEffect(() => {
    import(`../images/awards/${award.type}.png`).then(module => {
      setImageSrc(module.default)
    })
  }, [award])
  return (
    <a href={award.link.href} target={award.link.target}>
      <img src={imageSrc} alt={award.alt} className="award" />
    </a>
  )
}

export const Logo: FunctionComponent<ILogoProps> = ({
  src,
  alt,
  color,
  award,
  link,
}) => {
  const LogoHolder = src ? (
    <img src={src} alt={alt} className="logo" />
  ) : color === "white" ? (
    <LogoWhite className="logo" />
  ) : color === "group" ? (
    <LogoGroup className="logo" />
  ) : (
    <LogoBlack className="logo" />
  )
  return (
    <section className="logo-holder">
      {link && (
        <a href={link.href} target={link.target}>
          {LogoHolder}
        </a>
      )}
      {!link && LogoHolder}
      {award && !isMobile && <AwardImage award={award} />}
    </section>
  )
}

export default Logo
