import React, { FunctionComponent, Fragment } from "react"
import "./MediaCentre.scss"
import { IMediaCentreItem } from "../types"
import classNames from "classnames"
import { Button } from "./Button"

interface IMediaCentreProps {
  items: IMediaCentreItem[]
}
export const MediaCentre: FunctionComponent<IMediaCentreProps> = ({
  items,
}) => {
  return (
    <section className="mediacentre">
      <h2>Media Centre</h2>
      <div className="mediacentre-container">
        {items.map((item, index) => (
          <Fragment key={index}>
            <article>
              <figure>
                <a href={item.link} target="blank">
                  <img src={item.thumbnail.url[0]} width="100%" height="auto" />
                </a>
              </figure>
              <h3>
                <a href={item.link} target="_blank">
                  {item.title}
                </a>
              </h3>
              <p>{item.contentSnippet.substring(0, 180)}...</p>
              <p className="more">
                <a href={item.link} target="blank">
                  READ MORE
                </a>
              </p>
            </article>
          </Fragment>
        ))}
        <div className="btn-container">
          <Button
            size="medium"
            className="neue gray"
            onClick={() =>
              (window.location.href =
                "https://ovolohotels.com/connect/o-press-releases/")
            }
          >
            {"See More"}
          </Button>
        </div>
      </div>
    </section>
  )
}
export default MediaCentre
