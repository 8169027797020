import React, { FunctionComponent, useState, useEffect, useRef } from "react"
import classNames from "classnames"

import logo from "../images/ovolo-group-logo.png"
import icon from "../images/group-icon.png"
import MenuIcon from "../icons/icon-menu.svg"
import MapHK from "../icons/icon-hk-map.svg"
import MapAU from "../icons/icon-hk-map.svg"
import LogoMojo from "../icons/logo-mojo.svg"

import "./GlobalMenu.scss"
import { INavigationItem } from "../types"
import { isMobile } from "react-device-detect"

const NavigationImage: FunctionComponent<{ item: INavigationItem }> = ({
  item,
}) => {
  if (item.title === "Hong Kong") {
    return <MapHK />
  } else if (item.title === "Australia") {
    return <MapAU />
  } else if (item === "logo-mojo") {
    return <LogoMojo />
  } else {
    return null
  }

  /*
  const [imageSrc, setImageSrc] = useState("//placehold.it/150x138")
  useEffect(() => {
    import(`../${item.image}`).then(module => {
      setImageSrc(module.default)
    })
  }, [item])
  return <img src={imageSrc} alt={item} />
  */
}

const Navigation: FunctionComponent<{
  items: INavigationItem[]
  footer: INavigationItem
  isClosing: boolean
  handleToggleOpen: () => void
}> = ({ items, isClosing = false, footer, handleToggleOpen }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setOpen(true), 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [items])

  const node = useRef<HTMLElement>(null)

  const handleClick = (e: any) => {
    if (node.current && node.current.contains(e.target)) {
      return
    }
    handleToggleOpen()
  }

  useEffect(() => {
    open && document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [items])

  return (
    <nav
      className={classNames({
        "global-menu-navigation": true,
        open: open && !isClosing,
      })}
      ref={node}
    >
      <img src={logo} alt="Ovolo Hotels" />
      <dl>
        {items.map((item, index) => (
          <div className="item" key={index}>
            <dd className="image">
              <NavigationImage item={item} />
            </dd>
            <dt>
              <a href={item.url} target={item.target}>
                {item.title}
              </a>
            </dt>
            {item.wordpress_children &&
              item.wordpress_children.map((subitem, index) => (
                <dd className="subitem" key={index}>
                  {subitem.classes && (
                    <NavigationImage item={subitem.classes} />
                  )}
                  <a href={subitem.url} target={subitem.target}>
                    {subitem.title}
                  </a>
                </dd>
              ))}
          </div>
        ))}
      </dl>
      <a className="ovolo" href={footer.url} target={footer.target}>
        {footer.title}
      </a>
    </nav>
  )
}
export const GlobalMenu: FunctionComponent<{
  open?: boolean
  items: INavigationItem[]
  handleToggleNavigation?: () => void
  footer: INavigationItem
}> = ({ open = false, items = [], handleToggleNavigation, footer }) => {
  const [isOpen, setOpen] = useState(open)
  const [isClosing, setWillClose] = useState(false)
  const handleToggleOpen = () => {
    if (isMobile) {
      handleToggleNavigation && handleToggleNavigation()
    }
    if (!isOpen) {
      setOpen(true)
      setWillClose(false)
    } else {
      setWillClose(true)
      setTimeout(() => {
        setOpen(false)
      }, 200)
    }
  }

  return (
    <section className="global-menu">
      {!isMobile && (
        <img
          src={icon}
          onClick={handleToggleOpen}
          className="global-menu-logo"
        />
      )}
      <MenuIcon onClick={handleToggleOpen} className="global-menu-icon" />
      {!isMobile && isOpen && (
        <Navigation
          handleToggleOpen={handleToggleOpen}
          items={items}
          isClosing={isClosing}
          footer={footer}
        />
      )}
    </section>
  )
}

export default GlobalMenu
