import React from "react"

import "./Button.scss"
import classNames from "classnames"

export const Button: React.FunctionComponent<{
  className?: string
  size?: "small" | "medium" | "default"
  trashhand?: boolean
  type?: "submit" | "reset" | "button"
  disabled?: boolean
  dark?: boolean
  buttonStyle?: object
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}> = ({
  children,
  className,
  onClick,
  size = "",
  trashhand,
  dark,
  type = "button",
  disabled = false,
  buttonStyle,
}) => (
  <button
    className={classNames("btn", className, {
      [size]: size,
      trashhand,
      dark,
      disabled,
    })}
    style={buttonStyle}
    onClick={onClick}
    disabled={disabled}
    type={type}
  >
    {children}
  </button>
)
