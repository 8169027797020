import React from "react"
import {
  IAward,
  INavigationItem,
  ISocialNetwork,
  ICarouselItem,
  IHotelConfig,
  IKeyPar,
  IPropriety,
  ITable,
  ISubNavigationItem,
  IVideoCarouselItem,
  IOfferItem,
  ITestimonialCarouselItem,
  IListingItem,
} from "./types"
import { ContentImage } from "./components/ContentImage"
import { Button } from "./components/Button"

const WP_URL =
  process.env.WP_URL == null ? "https://wp.ovologroup.com" : process.env.WP_URL

export const INLINE_IMAGE_GALLERY_ITEMS: ICarouselItem[] = [
  {
    image:
      "https://s1lq54vg3v2i1wd71pozno1b-wpengine.netdna-ssl.com/wp-content/themes/themify-split-child/freebies/woolloomooloo-tile.jpg",
    title: "Woolloomooloo",
    link: { href: "https://ovolohotels.com.au/ovolowoolloomooloo/" },
  },
  {
    image:
      "https://s1lq54vg3v2i1wd71pozno1b-wpengine.netdna-ssl.com/wp-content/themes/themify-split-child/freebies/darling-tile.jpg",
    title: "1888",
    link: { href: "https://ovolohotels.com.au/ovolowoolloomooloo/" },
  },
  {
    image:
      "https://s1lq54vg3v2i1wd71pozno1b-wpengine.netdna-ssl.com/wp-content/themes/themify-split-child/freebies/melbourne-tile.jpg",
    title: "Laneways",
    link: { href: "https://ovolohotels.com.au/ovolowoolloomooloo/" },
  },
  {
    image:
      "https://s1lq54vg3v2i1wd71pozno1b-wpengine.netdna-ssl.com/wp-content/themes/themify-split-child/freebies/woolloomooloo-tile.jpg",
    title: "Woolloomooloo",
    link: { href: "https://ovolohotels.com.au/ovolowoolloomooloo/" },
  },
  {
    image:
      "https://s1lq54vg3v2i1wd71pozno1b-wpengine.netdna-ssl.com/wp-content/themes/themify-split-child/freebies/darling-tile.jpg",
    title: "1888",
    link: { href: "https://ovolohotels.com.au/ovolowoolloomooloo/" },
  },
  {
    image:
      "https://s1lq54vg3v2i1wd71pozno1b-wpengine.netdna-ssl.com/wp-content/themes/themify-split-child/freebies/ovolothevalley-freebies.jpg",
    title: "Ovolo the valley",
    link: { href: "https://ovolohotels.com.au/ovolowoolloomooloo/" },
  },
]

export const POST_IMAGE_CAROUSEL: ICarouselItem[] = [
  {
    image:
      "https://2ureqn2qd5go3p1q6h3bylwd-wpengine.netdna-ssl.com/ovolosouthside/wp-content/uploads/sites/15/2016/08/OvoloSouthside_DesignDetails.jpg",
    title: "Design details",
  },
  {
    image:
      "https://2ureqn2qd5go3p1q6h3bylwd-wpengine.netdna-ssl.com/ovolosouthside/wp-content/uploads/sites/15/2016/08/OvoloSouthside_Bathroom_biology_26_jan.jpg",
    title: "Bathroom",
  },
  {
    image:
      "https://2ureqn2qd5go3p1q6h3bylwd-wpengine.netdna-ssl.com/ovolosouthside/wp-content/uploads/sites/15/2019/04/Mini-Twin-800x450.jpg",
    title: "Mini Twin",
  },
  {
    image:
      "https://2ureqn2qd5go3p1q6h3bylwd-wpengine.netdna-ssl.com/ovolosouthside/wp-content/uploads/sites/15/2019/04/Deluxe-Queen-800x450.jpg",
    title: "Deluxe Queen",
  },
  {
    image:
      "https://2ureqn2qd5go3p1q6h3bylwd-wpengine.netdna-ssl.com/ovolosouthside/wp-content/uploads/sites/15/2019/04/Corner-800x450.jpg",
    title: "Corner",
  },
  {
    image:
      "https://2ureqn2qd5go3p1q6h3bylwd-wpengine.netdna-ssl.com/ovolosouthside/wp-content/uploads/sites/15/2019/04/Corner-Deluxe-2-800x450.jpg",
    title: "Corner Deluxe",
  },
]

export const OFFERS: IOfferItem[] = [
  {
    image:
      "https://2ureqn2qd5go3p1q6h3bylwd-wpengine.netdna-ssl.com/ovolosouthside/wp-content/uploads/2019/04/vicevicebaby-02.jpg",
    title: "Vice Vice Baby! Chocolate, Wine & 25% off…",
    link: {
      href:
        "https://be.synxis.com/?Hotel=59960&Chain=15668&promo=OVOLOSUMMER&_gl=1*w28lzb*_gcl_aw*R0NMLjE1NTAxODQxNTcuQ2p3S0NBaUF3SlRqQlJCaEVpd0E1NlY3cXdlVXZZTnRoUnhnR1NZeHRSTlBYSnFkXzAyNUR4aHBZM0h6LUJJRHFVQmJ5b0g0TUp0LUVSb0N1SUVRQXZEX0J3RQ..&_ga=2.92581784.406580659.1554856309-2085196689.1547608536",
    },
    content: (
      <div className="modal-offer post">
        <h4>Vice Vice Baby! Chocolate, Wine & 25% off…</h4>
        <img src="https://2ureqn2qd5go3p1q6h3bylwd-wpengine.netdna-ssl.com/ovolosouthside/wp-content/uploads/2019/04/vicevicebaby-02.jpg" />
        <p>
          Vice vice baby Vanilla
          <br /> Vice vice baby Chocolate
          <br /> Vice vice baby Wine
        </p>
        <p>It’s Time</p>
        <p>
          I don’t know about you but I’ve got my vices and I like ‘em! Roll the
          dice and bring twice the vice.
        </p>
        <p>
          All right stop
          <br /> Collaborate and listen
        </p>
        <p>Ovolo is back with a brand new invention</p>
        <p>
          <strong>2 nights stay and you’ll save 25%</strong>
        </p>
        <p>
          With{" "}
          <a href="https://www.lindt.com.au/shop/lindor-aus/gift-boxes-aus/lindor-assorted-gift-box-150g-aus">
            Lindt Chocolates
          </a>{" "}
          for smiles and{" "}
          <a href="https://mcwilliams.com.au/our-range/mcw-480/mcwilliams-480-shiraz-2015">
            McWilliams Wine
          </a>{" "}
          for the vibes
        </p>
        <p>
          Vice vice baby
          <br /> Vice vice
          <br /> Vice vice baby
        </p>
        <p>
          <em>For a Limited Time Time Baby</em>
        </p>
        <div style={{ overflow: "auto", width: "100%" }}>
          <Button
            size="small"
            onClick={() =>
              (window.location.href =
                "https://be.synxis.com/?Hotel=59960&Chain=15668&promo=OVOLOSUMMER&_gl=1*w28lzb*_gcl_aw*R0NMLjE1NTAxODQxNTcuQ2p3S0NBaUF3SlRqQlJCaEVpd0E1NlY3cXdlVXZZTnRoUnhnR1NZeHRSTlBYSnFkXzAyNUR4aHBZM0h6LUJJRHFVQmJ5b0g0TUp0LUVSb0N1SUVRQXZEX0J3RQ..&_ga=2.92581784.406580659.1554856309-2085196689.1547608536")
            }
            buttonStyle={{ float: "right", padding: "1rem 2.4rem" }}
          >
            Book now
          </Button>
        </div>
      </div>
    ),
  },
  {
    image:
      "https://2ureqn2qd5go3p1q6h3bylwd-wpengine.netdna-ssl.com/ovolosouthside/wp-content/uploads/2019/04/vicevicebaby-02.jpg",
    title: "Vice Vice Baby! Chocolate, Wine",
    link: {
      href:
        "https://be.synxis.com/?Hotel=59960&Chain=15668&promo=OVOLOSUMMER&_gl=1*w28lzb*_gcl_aw*R0NMLjE1NTAxODQxNTcuQ2p3S0NBaUF3SlRqQlJCaEVpd0E1NlY3cXdlVXZZTnRoUnhnR1NZeHRSTlBYSnFkXzAyNUR4aHBZM0h6LUJJRHFVQmJ5b0g0TUp0LUVSb0N1SUVRQXZEX0J3RQ..&_ga=2.92581784.406580659.1554856309-2085196689.1547608536",
    },
    content: (
      <div className="modal-offer post">
        <h4>Vice Vice Baby! Chocolate, Wine & 25% off…</h4>
        <img src="https://2ureqn2qd5go3p1q6h3bylwd-wpengine.netdna-ssl.com/ovolosouthside/wp-content/uploads/2019/04/vicevicebaby-02.jpg" />
        <p>
          Vice vice baby Vanilla
          <br /> Vice vice baby Chocolate
          <br /> Vice vice baby Wine
        </p>
        <p>It’s Time</p>
        <p>
          I don’t know about you but I’ve got my vices and I like ‘em! Roll the
          dice and bring twice the vice.
        </p>
        <p>
          All right stop
          <br /> Collaborate and listen
        </p>
        <p>Ovolo is back with a brand new invention</p>
        <p>
          <strong>2 nights stay and you’ll save 25%</strong>
        </p>
        <p>
          With{" "}
          <a href="https://www.lindt.com.au/shop/lindor-aus/gift-boxes-aus/lindor-assorted-gift-box-150g-aus">
            Lindt Chocolates
          </a>{" "}
          for smiles and{" "}
          <a href="https://mcwilliams.com.au/our-range/mcw-480/mcwilliams-480-shiraz-2015">
            McWilliams Wine
          </a>{" "}
          for the vibes
        </p>
        <p>
          Vice vice baby
          <br /> Vice vice
          <br /> Vice vice baby
        </p>
        <p>
          <em>For a Limited Time Time Baby</em>
        </p>
        <div style={{ overflow: "auto", width: "100%" }}>
          <Button
            size="small"
            onClick={() =>
              (window.location.href =
                "https://be.synxis.com/?Hotel=59960&Chain=15668&promo=OVOLOSUMMER&_gl=1*w28lzb*_gcl_aw*R0NMLjE1NTAxODQxNTcuQ2p3S0NBaUF3SlRqQlJCaEVpd0E1NlY3cXdlVXZZTnRoUnhnR1NZeHRSTlBYSnFkXzAyNUR4aHBZM0h6LUJJRHFVQmJ5b0g0TUp0LUVSb0N1SUVRQXZEX0J3RQ..&_ga=2.92581784.406580659.1554856309-2085196689.1547608536")
            }
            buttonStyle={{ float: "right", padding: "1rem 2.4rem" }}
          >
            Book now
          </Button>
        </div>
      </div>
    ),
  },
  {
    image:
      "https://2ureqn2qd5go3p1q6h3bylwd-wpengine.netdna-ssl.com/ovolosouthside/wp-content/uploads/2019/04/vicevicebaby-02.jpg",
    title: "Vice Vice Baby! Chocolate, Wine & 25% off…",
    link: {
      href:
        "https://be.synxis.com/?Hotel=59960&Chain=15668&promo=OVOLOSUMMER&_gl=1*w28lzb*_gcl_aw*R0NMLjE1NTAxODQxNTcuQ2p3S0NBaUF3SlRqQlJCaEVpd0E1NlY3cXdlVXZZTnRoUnhnR1NZeHRSTlBYSnFkXzAyNUR4aHBZM0h6LUJJRHFVQmJ5b0g0TUp0LUVSb0N1SUVRQXZEX0J3RQ..&_ga=2.92581784.406580659.1554856309-2085196689.1547608536",
    },
    content: (
      <div className="modal-offer post">
        <h4>Vice Vice Baby! Chocolate, Wine & 25% off…</h4>
        <img src="https://2ureqn2qd5go3p1q6h3bylwd-wpengine.netdna-ssl.com/ovolosouthside/wp-content/uploads/2019/04/vicevicebaby-02.jpg" />
        <p>
          Vice vice baby Vanilla
          <br /> Vice vice baby Chocolate
          <br /> Vice vice baby Wine
        </p>
        <p>It’s Time</p>
        <p>
          I don’t know about you but I’ve got my vices and I like ‘em! Roll the
          dice and bring twice the vice.
        </p>
        <p>
          All right stop
          <br /> Collaborate and listen
        </p>
        <p>Ovolo is back with a brand new invention</p>
        <p>
          <strong>2 nights stay and you’ll save 25%</strong>
        </p>
        <p>
          With{" "}
          <a href="https://www.lindt.com.au/shop/lindor-aus/gift-boxes-aus/lindor-assorted-gift-box-150g-aus">
            Lindt Chocolates
          </a>{" "}
          for smiles and{" "}
          <a href="https://mcwilliams.com.au/our-range/mcw-480/mcwilliams-480-shiraz-2015">
            McWilliams Wine
          </a>{" "}
          for the vibes
        </p>
        <p>
          Vice vice baby
          <br /> Vice vice
          <br /> Vice vice baby
        </p>
        <p>
          <em>For a Limited Time Time Baby</em>
        </p>
        <div style={{ overflow: "auto", width: "100%" }}>
          <Button
            size="small"
            onClick={() =>
              (window.location.href =
                "https://be.synxis.com/?Hotel=59960&Chain=15668&promo=OVOLOSUMMER&_gl=1*w28lzb*_gcl_aw*R0NMLjE1NTAxODQxNTcuQ2p3S0NBaUF3SlRqQlJCaEVpd0E1NlY3cXdlVXZZTnRoUnhnR1NZeHRSTlBYSnFkXzAyNUR4aHBZM0h6LUJJRHFVQmJ5b0g0TUp0LUVSb0N1SUVRQXZEX0J3RQ..&_ga=2.92581784.406580659.1554856309-2085196689.1547608536")
            }
            buttonStyle={{ float: "right", padding: "1rem 2.4rem" }}
          >
            Book now
          </Button>
        </div>
      </div>
    ),
  },
  {
    image:
      "https://2ureqn2qd5go3p1q6h3bylwd-wpengine.netdna-ssl.com/ovolosouthside/wp-content/uploads/2019/04/vicevicebaby-02.jpg",
    title: "Vice Vice Baby! Chocolate, Wine",
    link: {
      href:
        "https://be.synxis.com/?Hotel=59960&Chain=15668&promo=OVOLOSUMMER&_gl=1*w28lzb*_gcl_aw*R0NMLjE1NTAxODQxNTcuQ2p3S0NBaUF3SlRqQlJCaEVpd0E1NlY3cXdlVXZZTnRoUnhnR1NZeHRSTlBYSnFkXzAyNUR4aHBZM0h6LUJJRHFVQmJ5b0g0TUp0LUVSb0N1SUVRQXZEX0J3RQ..&_ga=2.92581784.406580659.1554856309-2085196689.1547608536",
    },
    content: (
      <div className="modal-offer post">
        <h4>Vice Vice Baby! Chocolate, Wine & 25% off…</h4>
        <img src="https://2ureqn2qd5go3p1q6h3bylwd-wpengine.netdna-ssl.com/ovolosouthside/wp-content/uploads/2019/04/vicevicebaby-02.jpg" />
        <p>
          Vice vice baby Vanilla
          <br /> Vice vice baby Chocolate
          <br /> Vice vice baby Wine
        </p>
        <p>It’s Time</p>
        <p>
          I don’t know about you but I’ve got my vices and I like ‘em! Roll the
          dice and bring twice the vice.
        </p>
        <p>
          All right stop
          <br /> Collaborate and listen
        </p>
        <p>Ovolo is back with a brand new invention</p>
        <p>
          <strong>2 nights stay and you’ll save 25%</strong>
        </p>
        <p>
          With{" "}
          <a href="https://www.lindt.com.au/shop/lindor-aus/gift-boxes-aus/lindor-assorted-gift-box-150g-aus">
            Lindt Chocolates
          </a>{" "}
          for smiles and{" "}
          <a href="https://mcwilliams.com.au/our-range/mcw-480/mcwilliams-480-shiraz-2015">
            McWilliams Wine
          </a>{" "}
          for the vibes
        </p>
        <p>
          Vice vice baby
          <br /> Vice vice
          <br /> Vice vice baby
        </p>
        <p>
          <em>For a Limited Time Time Baby</em>
        </p>
        <div style={{ overflow: "auto", width: "100%" }}>
          <Button
            size="small"
            onClick={() =>
              (window.location.href =
                "https://be.synxis.com/?Hotel=59960&Chain=15668&promo=OVOLOSUMMER&_gl=1*w28lzb*_gcl_aw*R0NMLjE1NTAxODQxNTcuQ2p3S0NBaUF3SlRqQlJCaEVpd0E1NlY3cXdlVXZZTnRoUnhnR1NZeHRSTlBYSnFkXzAyNUR4aHBZM0h6LUJJRHFVQmJ5b0g0TUp0LUVSb0N1SUVRQXZEX0J3RQ..&_ga=2.92581784.406580659.1554856309-2085196689.1547608536")
            }
            buttonStyle={{ float: "right", padding: "1rem 2.4rem" }}
          >
            Book now
          </Button>
        </div>
      </div>
    ),
  },
]

export const ARTICLE_ITEMS: IListingItem[] = [
  {
    title: "Your Complete Guide to What’s On in Hong Kong",
    callout: {
      title: "View article",
      link: {
        href: "https://thehkhub.com/events-whats-on-in-hong-kong/",
      },
    },
    image: {
      title: "The HK Hub",
      link: {
        href: "https://thehkhub.com/",
        target: "_blank",
      },
      src:
        "https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2019/02/the-hk-hub.jpg",
    },
    year: "2019",
    month: "Jan",
    day: "30",
  },
  {
    title: "New Restaurants: February 2019",
    callout: {
      title: "View article",
      link: {
        href:
          "https://www.afoodieworld.com/foodie/2019-01-18-new-restaurants-february-2019",
      },
    },
    image: {
      title: "A Foodie World",
      link: {
        href: "https://www.afoodieworld.com/",
        target: "_blank",
      },
      src:
        "https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2019/02/foodie-logo-150x150.jpg",
    },
    year: "2019",
    month: "Jan",
    day: "24",
  },
  {
    title: "VEDA: THE VEGETARIAN RESTAURANT THAT’S SURE TO SATISFY MEAT EATERS",
    callout: {
      title: "View article",
      link: {
        href:
          "https://taneresidence.com/veda-the-vegetarian-restaurant-thats-sure-to-satisfy-meat-eaters/",
      },
    },
    image: {
      title: "The HK Hub",
      link: {
        href: "https://taneresidence.com/",
        target: "_blank",
      },
      src:
        "https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2019/02/tane-residence.jpg",
    },
    year: "2019",
    month: "Jan",
    day: "22",
  },
]

export const CAREER_ITEMS: IListingItem[] = [
  {
    title: "Housekeeping Supervisor 房務部主管",
    paragraphs: [
      "What you will do 工作範圍: Responsible to manage Housekeeping team 負責管理酒店房務部 Manage department’s monthly inventory control...",
    ],
    year: "2019",
    month: "Apr",
    day: "08",
    callout: {
      title: "Read more",
      link: {
        href: "https://ovolohotels.com/careers/restaurant-supervisor-copy/",
      },
    },
    secondaryCallout: {
      title: "Apply now",
      link: { href: "mailto:hr@ovologroup.com" },
    },
  },
  {
    title: "Restaurant Server / Host / Hostess 餐廳侍應 / 接待員",
    paragraphs: [
      "Server – A Glimpse of what you’ll do 餐廳侍應 – 職責範圍: Provide bubbly, quality service in our restaurant 提供優質及有活力的餐飲服務 Handle daily side duties assigned 負責餐廳日常樓面職務...",
    ],
    year: "2019",
    month: "Apr",
    day: "08",
    callout: {
      title: "Read more",
      link: {
        href: "https://ovolohotels.com/careers/restaurant-supervisor/",
      },
    },
    secondaryCallout: {
      title: "Apply now",
      link: { href: "mailto:hr@ovologroup.com" },
    },
  },
]

export const NEWSLETTER_ITEMS: IListingItem[] = [
  {
    title: "March 2019",
    callout: {
      title: "View newsletter",
      link: {
        href: "https://ovolohotels.com/omg_newsletter/omg-march-2019/",
      },
    },
  },
  {
    title: "February 2019",
    callout: {
      title: "View newsletter",
      link: {
        href: "https://ovolohotels.com/omg_newsletter/february-2019/",
      },
    },
  },
  {
    title: "January 2019",
    callout: {
      title: "View newsletter",
      link: {
        href: "https://ovolohotels.com/omg_newsletter/january-2019/",
      },
    },
  },
]

export const TESTIMONIAL_CAROUSEL_ITEMS: ITestimonialCarouselItem[] = [
  {
    paragraphs: [
      "My time in Ovolo has been one of the most valuable experieces to date. I was accepted as part of the team right away, and gained valuable knowledge in all the departments I was assigned. Management showed interest and were more than happy to share their knowledge. The exponential amount of skills that I’ve acquired here will most certainly prepare me for my future career within the hospitality industry. Thank you, Ovolo! Xoxo",
      "– Fayme Patel, Robert Gordon University, Scotland (2015)",
    ],
    title: "Fayme Patel",
    image:
      "https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2016/11/Fayme1.png",
  },
  {
    paragraphs: [
      "I really enjoyed my internship period with Ovolo! Never did I feel that I was being treated like \"just-an-intern\", everyone has been so kind and willing to teach me, I can't agree more with Ovolo's mission in creating a happy and productive workplace. :)",
      "– Hui Yin Tan, Nanyans Polytechnic University, Singapore (2015)",
    ],
    title: "Hui Yin Tan",
    image:
      "https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2016/11/Hin1.png",
  },
  {
    paragraphs: [
      "Indescribable. That’s the word I use when I talk about this internship programme. It is impossible to sum up a years’ worth of experience in a paragraph because words like fun, awesome, beneficial, exciting, intense, and rewarding all have a number of stories behind them. Ovolo is the most unique company and provides the most rewarding training. It’s also probably the only company out there that allows you to experience working in all areas of a hotel company – operations and corporate wise. I highly recommend Ovolo to anyone who wants to experience working in a boutique hotel company. The skills and habits I’ve acquired here, I wouldn’t have been able to anywhere else. ☺",
      "– Emilija Asmonaite, University of Huddersfield, England (2015)",
    ],
    title: "Emilija Asmonaite",
    image:
      "https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2016/11/emilija1.png",
  },
  {
    paragraphs: [
      "My placement with Ovolo Hotels has allowed me to experience the operation side of both hotels and food &amp; beverage as well as office experience. It has allowed me to developed a good connection with the people in Hong Kong and worldwide through the people who I have met. It was one of the most interesting experiences and I properly had the happiest moments during my time with Ovolo Hotels and I would like to thank them with the bottom of my heart. Thank you Ovolo Hotels and the wonderful staff!",
      "– Sally Chee, University of Surrey, England (2015)",
    ],
    title: "Sally Chee",
    image:
      "https://44epof2n91t147auce2hh5vo-wpengine.netdna-ssl.com/wp-content/uploads/2016/11/sally1.png",
  },
]

export const VIDEO_CAROUSEL_ITEMS: IVideoCarouselItem[] = [
  {
    title:
      "Ovolo CEO + Founder Girish Jhunjhnuwala on Business Class, Sky News",
    image: "//img.youtube.com/vi/KWYTVFpI-s8/hqdefault.jpg",
    videoId: "KWYTVFpI-s8",
    link: {
      href: "http://www.youtube.com/watch/KWYTVFpI-s8",
      target: "_blank",
    },
  },
  {
    title: "China-Hong Kong: Girish Jhunjhnuwala, Ovolo Group",
    image: "//img.youtube.com/vi/040LioeDs5Y/hqdefault.jpg",
    videoId: "040LioeDs5Y",
    link: {
      href: "http://www.youtube.com/watch/040LioeDs5Y",
      target: "_blank",
    },
  },
  {
    title: "Ovolo CEO Girish Jhunjhnuwala",
    image: "//img.youtube.com/vi/S2pD9KVNwL8/hqdefault.jpg",
    videoId: "S2pD9KVNwL8",
    link: {
      href: "http://www.youtube.com/watch/S2pD9KVNwL8",
      target: "_blank",
    },
  },
  {
    title:
      "Ovolo Hotels - Girish Jhunjhnuwala - EY Entrepreneur Of The Year - Hong Kong / Macau Region (2016)",
    image: "//img.youtube.com/vi/Xaqi5elhbaI/hqdefault.jpg",
    videoId: "Xaqi5elhbaI",
    link: {
      href: "http://www.youtube.com/watch/Xaqi5elhbaI",
      target: "_blank",
    },
  },
  {
    title:
      "Insider Video: Discover Ovolo's Australia &amp; Hong Kong Boutique Hotels",
    image: "//img.youtube.com/vi/wmdq-SOI3gs/hqdefault.jpg",
    videoId: "wmdq-SOI3gs",
    link: {
      href: "http://www.youtube.com/watch/wmdq-SOI3gs",
      target: "_blank",
    },
  },
]

export const INTERNSHIP_SUBNAV_ITEMS: ISubNavigationItem[] = [
  {
    text: "Hong Kong Internship Programme",
    active: true,
    link: { href: "https://ovolohotels.com/internship/#tab_a" },
  },
  {
    text: "Australia Internship Programme",
    link: { href: "https://ovolohotels.com/internship/#tab_b" },
  },
]

export const ARTICLES_SUBNAV_ITEMS: ISubNavigationItem[] = [
  {
    text: "O-Press articles",
    active: true,
    link: { href: "https://ovolohotels.com/connect/o-press-articles/" },
  },
  {
    text: "O-Press releases",
    link: { href: "https://ovolohotels.com/connect/o-press-releases/" },
  },
  {
    text: "O-Press videos",
    link: { href: "https://ovolohotels.com/connect/o-press-videos/" },
  },
]

export const CAREERS_SUBNAV_ITEMS: ISubNavigationItem[] = [
  {
    text: "All",
    active: true,
    link: { href: "https://ovolohotels.com/careers/" },
  },
  {
    text: "Honk Kong",
    link: { href: "https://ovolohotels.com/careers/" },
  },
  {
    text: "Australia",
    link: { href: "https://ovolohotels.com/careers/" },
  },
]

export const SUBNAVIGATION_ITEMS: ISubNavigationItem[] = [
  {
    text: "Background & Mission",
    active: true,
    link: { href: "https://ovolohotels.com/background-mission/" },
  },
  {
    text: "Team Ovolo",
    link: { href: "https://ovolohotels.com/team-ovolo/" },
  },
  {
    text: "Awards",
    link: { href: "https://ovolohotels.com/ovolo-awards/" },
  },
  {
    text: "Careers",
    link: { href: "https://ovolohotels.com/careers/" },
  },
  {
    text: "Internship",
    link: { href: "https://ovolohotels.com/internship/" },
  },
  {
    text: "Management Trainee",
    link: { href: "https://ovolohotels.com/trainee/" },
  },
]

export const FACILITIES_TABLE: ITable = {
  header: [
    { content: "" },
    { content: "Rooms", textTransform: "uppercase" },
    { content: "Must have", textTransform: "uppercase" },
    { content: "Optional", textTransform: "uppercase" },
  ],
  rows: [
    {
      cells: [
        {
          textAlign: "left",
          content: <ContentImage image="logo-ovolo.png" title="Ovolo Hotel" />,
        },
        {
          content: (
            <ul>
              <li>From 16 sqm but with a majority over 18 sqm</li>
              <li>Private rooms</li>
            </ul>
          ),
        },
        {
          content: (
            <ul>
              <li>
                Restaurant/bar, social areas, Lobby, Parking, Free Self-Laundry
              </li>
              <li>WOW design, look and feel</li>
            </ul>
          ),
        },
        {
          content: (
            <ul>
              <li>Meeting Space, Pool, Gym</li>
            </ul>
          ),
        },
      ],
    },
    {
      cells: [
        {
          textAlign: "left",
          content: <ContentImage image="logo-mojo.png" title="Mojo Nomad" />,
        },
        {
          content: (
            <ul>
              <li>
                From 12 sqm up to 30 sqm but with a majority smaller than 22
                sqm.
              </li>
              <li>Private and shared rooms. (All with private bathrooms)</li>
            </ul>
          ),
        },
        {
          content: (
            <ul>
              <li>Bar, social areas, Self-Laundry</li>
              <li>WOW design, look and feel</li>
              <li>Work areas for the modern digital nomad</li>
            </ul>
          ),
        },
        {
          content: (
            <ul>
              <li>Restaurant, parking, Gym</li>
            </ul>
          ),
        },
      ],
    },
  ],
}

export const PORTIFOLIO_TABLE: ITable = {
  header: [
    { content: "" },
    { content: "Hotel" },
    { content: "Location" },
    { content: "# Rooms" },
    { content: "Operating Model" },
    { content: "Open date" },
    { content: "Hotel link page" },
  ],
  rows: [
    {
      cells: [
        {
          textAlign: "left",
          content: (
            <ContentImage image="ovolo-central.png" title="Ovolo Central" />
          ),
        },
        { content: <span>Ovolo Central</span> },
        { content: "Central, HK" },
        { content: "42" },
        { content: "Ownership" },
        { content: "Sep, 2002" },
        {
          content: (
            <a href="https://ovolohotels.com.hk/?_ga=2.261267845.926165654.1551395213-2085196689.1547608536#first">
              Visit website
            </a>
          ),
        },
      ],
    },
    {
      cells: [
        {
          textAlign: "left",
          content: (
            <ContentImage image="ovolo-laneways.png" title="Ovolo Central" />
          ),
        },
        { content: <span>Ovolo Laneways</span> },
        { content: "Melbourne, AU" },
        { content: "43" },
        { content: "Ownership" },
        { content: "Sep, 2012" },
        {
          content: (
            <a href="https://ovolohotels.com.hk/?_ga=2.261267845.926165654.1551395213-2085196689.1547608536#first">
              Visit website
            </a>
          ),
        },
      ],
    },
    {
      cells: [
        {
          textAlign: "left",
          content: (
            <ContentImage image="ovolo-oaks.png" title="Ovolo Central" />
          ),
        },
        { content: <span>Oaks on Londsdale</span> },
        { content: "Melbourne, AU" },
        { content: "148" },
        { content: "Leased to 3rd Party" },
        { content: "2014" },
        {
          content: (
            <a href="https://ovolohotels.com.hk/?_ga=2.261267845.926165654.1551395213-2085196689.1547608536#first">
              Visit website
            </a>
          ),
        },
      ],
    },
  ],
}

export const PROPRIETIES_ITEMS: IPropriety[] = [
  {
    title: "Ovolo WoollooMooLoo",
    link: {
      href: "https://ovolohotels.com.au/ovolowoolloomooloo",
      target: "_blank",
    },
    callout: "Visit site",
    calloutColor: "#85cf9b",
    background: "light",
    image:
      "https://s1lq54vg3v2i1wd71pozno1b-wpengine.netdna-ssl.com/wp-content/uploads/2016/06/Ovolo_woolloomooloo_logo.png",
  },
  {
    title: "Ovolo 1888 Darling Harbour",
    link: {
      href: "https://ovolohotels.com.au/ovolo1888darlingharbour",
      target: "_blank",
    },
    callout: "Visit site",
    calloutColor: "#ecc986",
    background: "dark",
    image: "Ovolo_1888_Darling_Harbour_logo_white.png",
  },
  {
    title: "Ovolo Laneways",
    link: {
      href: "https://ovolohotels.com.au/ovololaneways",
      target: "_blank",
    },
    callout: "Visit site",
    calloutColor: "#ff4d5b",
    background: "light",
    image: "Ovolo_Laneways_logo.png",
  },
  {
    title: "Ovolo Inchcolm",
    link: {
      href: "https://ovolohotels.com.au/ovoloinchcolm",
      target: "_blank",
    },
    callout: "Visit site",
    calloutColor: "#0e6a37",
    background: "dark",
    image: "ovolo_inchcolm.png",
  },
  {
    title: "Ovolo Nishi",
    link: {
      href: "https://ovolohotels.com.au/ovolonishi/",
      target: "_blank",
    },
    callout: "Visit site",
    calloutColor: "#ff8200",
    background: "dark",
    image: "ovolo-nishi-main-1.png",
  },
  {
    title: "Ovolo WoollooMooLoo",
    link: {
      href: "https://ovolohotels.com.au/apartmentsnishi",
      target: "_blank",
    },
    callout: "Visit site",
    calloutColor: "#000",
    background: "light",
    image: "apartments-oficial.png",
  },
  {
    title: "Ovolo Valley",
    link: {
      href: "https://ovolohotels.com.au/ovolothevalley",
      target: "_blank",
    },
    callout: "Visit site",
    calloutColor: "#f38393",
    background: "dark",
    image: "ovolo-the-valley-logo.png",
  },
  {
    title: "Freebies",
    link: {
      href: "https://ovolohotels.com.au/freebies",
      target: "_blank",
    },
    background: "light",
    image: "freebies.png",
  },
]

export const BOOKING_CONFIG: IHotelConfig[] = [
  {
    name: "Select",
    id: "",
    isDisabled: true,
    hotelParam: "hotel",
    arriveParam: "arrive",
    departParam: "depart",
    dateFormat: "YYYY-MM-DD",
    guestsParam: "adult",
    postUrl: "https://be.synxis.com/",
    config: [
      {
        key: "chain",
        value: "15668",
      },
      {
        key: "lang",
        value: "1",
      },
      {
        key: "_ga",
        value: "2.118862665.1974394251.1550930737-2085196689.1547608536",
      },
    ],
  },
  {
    name: "Ovolo Woolloomooloo, Sydney (AU)",
    id: "62991",
    isDisabled: false,
    hotelParam: "hotel",
    arriveParam: "arrive",
    departParam: "depart",
    dateFormat: "YYYY-MM-DD",
    guestsParam: "adult",
    postUrl: "https://be.synxis.com/",
    config: [
      {
        key: "chain",
        value: "15668",
      },
      {
        key: "lang",
        value: "1",
      },
      {
        key: "_ga",
        value: "2.118862665.1974394251.1550930737-2085196689.1547608536",
      },
    ],
  },
  {
    name: "Ovolo 1888 Darling Harbour, Sydney (AU)",
    isDisabled: false,
    id: "63576",
    hotelParam: "hotel",
    arriveParam: "arrive",
    departParam: "depart",
    dateFormat: "YYYY-MM-DD",
    guestsParam: "adult",
    postUrl: "https://be.synxis.com/",
    config: [
      {
        key: "chain",
        value: "15668",
      },
      {
        key: "lang",
        value: "1",
      },
      {
        key: "_ga",
        value: "2.118862665.1974394251.1550930737-2085196689.1547608536",
      },
    ],
  },
  {
    name: "Ovolo Laneways, Melbourne (AU)",
    isDisabled: false,
    id: "60221",
    hotelParam: "hotel",
    arriveParam: "arrive",
    departParam: "depart",
    dateFormat: "YYYY-MM-DD",
    guestsParam: "adult",
    postUrl: "https://be.synxis.com/",
    config: [
      {
        key: "chain",
        value: "15668",
      },
      {
        key: "lang",
        value: "1",
      },
      {
        key: "_ga",
        value: "2.118862665.1974394251.1550930737-2085196689.1547608536",
      },
    ],
  },
  {
    name: "Ovolo Nishi, Canberra (AU)",
    isDisabled: false,
    id: "58784",
    hotelParam: "hotel",
    arriveParam: "arrive",
    departParam: "depart",
    dateFormat: "YYYY-MM-DD",
    guestsParam: "adult",
    postUrl: "https://gc.synxis.com/rez.aspx",
    config: [
      {
        key: "Chain",
        value: "5154",
      },
      {
        key: "lang",
        value: "1",
      },
      {
        key: "template",
        value: "RES_OVOLONISHI_V1",
      },
      {
        key: "shell",
        value: "RES_OVOLONISHI_V1",
      },
      {
        key: "locale",
        value: "en-GB",
      },
      {
        key: "src",
        value: "nishi",
      },
      {
        key: "ROOM",
        value: "COS,ORA,ORG,CRA,CRE,MEA",
      },
      {
        key: "_ga",
        value: "2.118862665.1974394251.1550930737-2085196689.1547608536",
      },
    ],
  },
  {
    name: "Nishi Apartments Eco Living by Ovolo, Canberra (AU)",
    id: "58784",
    isDisabled: false,
    hotelParam: "hotel",
    arriveParam: "arrive",
    departParam: "depart",
    dateFormat: "YYYY-MM-DD",
    guestsParam: "adult",
    postUrl: "https://gc.synxis.com/rez.aspx",
    config: [
      {
        key: "Chain",
        value: "5154",
      },
      {
        key: "lang",
        value: "1",
      },
      {
        key: "template",
        value: "RES_NISHI_AP_V1",
      },
      {
        key: "shell",
        value: "RES_NISHI_AP_V1",
      },
      {
        key: "locale",
        value: "en-GB",
      },
      {
        key: "src",
        value: "nishiap",
      },
      {
        key: "ROOM",
        value: "PAD,1BD,2BD,3BD,S1L",
      },
      {
        key: "_ga",
        value: "2.118862665.1974394251.1550930737-2085196689.1547608536",
      },
    ],
  },
  {
    name: "Ovolo Inchcolm, Brisbane (AU)",
    id: "97013",
    isDisabled: false,
    postUrl: "https://reservations.travelclick.com/97013",
    hotelParam: "hotelid",
    arriveParam: "dateIn",
    departParam: "dateOut",
    guestsParam: "adults",
    dateFormat: "MM/DD/YYYY",
    config: [
      {
        key: "themeid",
        value: "11950",
      },
      {
        key: "languageid",
        value: "1",
      },
      {
        key: "_ga",
        value: "2.118862665.1974394251.1550930737-2085196689.1547608536",
      },
    ],
  },
  {
    name: "Ovolo The Valley, Brisbane (AU)",
    id: "70687",
    isDisabled: false,
    postUrl: "https://reservations.travelclick.com/70687",
    hotelParam: "hotelid",
    arriveParam: "dateIn",
    departParam: "dateOut",
    guestsParam: "adults",
    dateFormat: "MM/DD/YYYY",
    maxGuests: 4,
    config: [
      {
        key: "themeid",
        value: "11950",
      },
      {
        key: "languageid",
        value: "1",
      },
      {
        key: "_ga",
        value: "2.118862665.1974394251.1550930737-2085196689.1547608536",
      },
    ],
  },
  {
    name: "Ovolo Southside, Hong Kong (HK)",
    id: "59960",
    isDisabled: false,
    hotelParam: "hotel",
    arriveParam: "arrive",
    departParam: "depart",
    dateFormat: "YYYY-MM-DD",
    guestsParam: "adult",
    maxGuests: 22,
    postUrl: "https://be.synxis.com/",
    config: [
      {
        key: "chain",
        value: "15668",
      },
      {
        key: "lang",
        value: "1",
      },
      {
        key: "_ga",
        value: "2.118862665.1974394251.1550930737-2085196689.1547608536",
      },
    ],
  },
  {
    name: "Ovolo Central, Hong Kong (HK)",
    id: "59958",
    isDisabled: false,
    hotelParam: "hotel",
    arriveParam: "arrive",
    departParam: "depart",
    dateFormat: "YYYY-MM-DD",
    guestsParam: "adult",
    postUrl: "https://be.synxis.com/",
    config: [
      {
        key: "chain",
        value: "15668",
      },
      {
        key: "lang",
        value: "1",
      },
      {
        key: "_ga",
        value: "2.118862665.1974394251.1550930737-2085196689.1547608536",
      },
    ],
  },
  {
    id: "mojo",
    name: "Mojo Nomad Aberdeen Harbour, Hong Kong (HK)",
    isDisabled: false,
    arriveParam: "checkin",
    departParam: "checkout",
    dateFormat: "YYYY-MM-DD",
    postUrl: "https://hotels.cloudbeds.com/reservation/HYksRJ",
    config: [
      {
        key: "start",
        value: "availresults",
      },
      {
        key: "Chain",
        value: "5154",
      },
      {
        key: "lang",
        value: "1",
      },
      {
        key: "template",
        value: "RES_NISHI_AP_V1",
      },
      {
        key: "shell",
        value: "RES_NISHI_AP_V1",
      },
      {
        key: "locale",
        value: "en-GB",
      },
      {
        key: "src",
        value: "nishiap",
      },
      {
        key: "ROOM",
        value: "PAD,1BD,2BD,3BD,S1L",
      },
      {
        key: "_ga",
        value: "2.260420557.1974394251.1550930737-2085196689.1547608536",
      },
    ],
  },
  {
    name: "The Sheung Wan, Hong Kong (HK)",
    id: "59963",
    isDisabled: false,
    hotelParam: "hotel",
    arriveParam: "arrive",
    departParam: "depart",
    dateFormat: "YYYY-MM-DD",
    guestsParam: "adult",
    postUrl: "https://be.synxis.com/",
    config: [
      {
        key: "_ga",
        value: "2.31219958.832164312.1560967548-1666725512.1557326967",
      },
      {
        key: "Chain",
        value: "15668",
      },
      {
        key: "currency",
        value: "HKD",
      },
      {
        key: "locale",
        value: "en-US",
      },
      {
        key: "sbe_ri",
        value: "0",
      },
      {
        key: "themecode",
        value: "mojocentral",
      },
    ],
  },
]

export const NEWSLETTER_CONFIG: IKeyPar[] = [
  {
    key: "pf_FormType",
    value: "OptInForm",
  },
  {
    key: "pf_OptInMethod",
    value: "SingleOptInMethod",
  },
  {
    key: "pf_CounterDemogrFields",
    value: "0",
  },
  {
    key: "pf_MailinglistName1",
    value: "211246",
  },
  {
    key: "pf_CounterMailinglists",
    value: "1",
  },
  {
    key: "pf_AccountId",
    value: "955",
  },
  {
    key: "pf_ListById",
    value: "1",
  },
  {
    key: "pf_Version",
    value: "2",
  },
]

export const SOCIAL_NETWORKS: ISocialNetwork[] = [
  {
    name: "Facebook",
    icon: "logo-facebook",
    link: { href: "https://www.facebook.com/OvoloHotels/", target: "_blank" },
  },
  {
    name: "Instagram",
    icon: "logo-instagram",
    link: { href: "https://www.instagram.com/ovolohotels/", target: "_blank" },
  },
  {
    name: "Linkedin",
    icon: "logo-linkedin",
    link: {
      href:
        "https://www.linkedin.com/company/ovolo-group-hotels-&-serviced-apartments",
      target: "_blank",
    },
  },
  {
    name: "Youtube",
    icon: "logo-youtube",
    link: { href: "https://www.youtube.com/user/ovologroup", target: "_blank" },
  },
]
export const NAVIGATION_FOOTER: INavigationItem = {
  name: "Ovolo Group Site",
  link: {
    href:
      "https://ovolohotels.com/?_gl=1*1qkx4gr*_gcl_aw*R0NMLjE1NTAxODQxNTcuQ2p3S0NBaUF3SlRqQlJCaEVpd0E1NlY3cXdlVXZZTnRoUnhnR1NZeHRSTlBYSnFkXzAyNUR4aHBZM0h6LUJJRHFVQmJ5b0g0TUp0LUVSb0N1SUVRQXZEX0J3RQ..&_ga=2.143467573.1868933330.1550184158-2085196689.1547608536&_gac=1.191548248.1550184158.CjwKCAiAwJTjBRBhEiwA56V7qweUvYNthRxgGSYxtRNPXJqd_025DxhpY3Hz-BIDqUBbyoH4MJt-ERoCuIEQAvD_BwE",
  },
}
export const NAVIGATION_ITEMS: INavigationItem[] = [
  {
    name: "Hong Kong",
    image: "icons/icon-hk-map.svg",
    link: {
      target: "_blank",
      href:
        "https://ovolohotels.com.hk/?_ga=2.21794552.2106402864.1549718394-2085196689.1547608536",
    },
    subitems: [
      {
        name: "Ovolo Southside",
        link: {
          target: "_blank",
          href:
            "https://ovolohotels.com.hk/ovolosouthside?_ga=2.201158637.2106402864.1549718394-2085196689.1547608536",
        },
      },
      {
        name: "Ovolo Central",
        link: {
          target: "_blank",
          href:
            "https://ovolohotels.com.hk/ovolocentral?_ga=2.201158637.2106402864.1549718394-2085196689.1547608536",
        },
      },
      {
        name: "Mojo Nomad Aberdeen",
        image: "icons/logo-mojo.svg",
        link: {
          target: "_blank",
          href:
            "https://www.mojonomad.com/aberdeen?_ga=2.202329682.2106402864.1549718394-2085196689.1547608536",
        },
      },
      {
        name: "Mojo Nomad Central",
        link: {
          target: "_blank",
          href:
            "https://www.mojonomad.com/central/?_ga=2.21801976.2106402864.1549718394-2085196689.1547608536",
        },
      },
    ],
  },
  {
    name: "Australia",
    image: "icons/icon-au-map.svg",
    link: {
      href:
        "https://ovolohotels.com.au/?_ga=2.260924238.2106402864.1549718394-2085196689.1547608536",
    },
    subitems: [
      {
        name: "Ovolo Woolloomooloo (Sydney)",
        link: {
          href:
            "https://ovolohotels.com.au/ovolowoolloomooloo?_ga=2.24530681.2106402864.1549718394-2085196689.1547608536",
        },
      },
      {
        name: "Ovolo 1888 Darling Harbour (Sydney)",
        link: {
          href:
            "https://ovolohotels.com.au/ovolo1888darlingharbour?_ga=2.24530681.2106402864.1549718394-2085196689.1547608536",
        },
      },
      {
        name: "Ovolo Laneways (Melbourne)",
        link: {
          href:
            "https://ovolohotels.com.au/ovololaneways?_ga=2.265062220.2106402864.1549718394-2085196689.1547608536",
        },
      },
      {
        name: "Ovolo Inchcolm   (Brisbane)",
        link: {
          href:
            "https://ovolohotels.com.au/ovoloinchcolm/?_ga=2.265062220.2106402864.1549718394-2085196689.1547608536",
        },
      },
      {
        name: "Ovolo Nishi (Canberra)",
        link: {
          href:
            "https://ovolohotels.com.au/ovolonishi?_ga=2.265062220.2106402864.1549718394-2085196689.1547608536",
        },
      },
      {
        name: "Nishi Apartments Eco Living by Ovolo (Canberra)",
        link: {
          href:
            "https://ovolohotels.com.au/apartmentsnishi?_ga=2.265062220.2106402864.1549718394-2085196689.1547608536",
        },
      },
      {
        name: "Ovolo The Valley (Brisbane)",
        link: {
          href:
            "https://ovolohotels.com.au/ovolothevalley/?_ga=2.265062220.2106402864.1549718394-2085196689.1547608536",
        },
      },
    ],
  },
  {
    name: "Offers",
    link: {
      href:
        "https://ovolohotels.com.au/?_ga=2.260924238.2106402864.1549718394-2085196689.1547608536",
    },
    subitems: [
      {
        name: "Ovolo Southside",
        link: {
          target: "_blank",
          href:
            "https://ovolohotels.com.hk/ovolosouthside?_ga=2.201158637.2106402864.1549718394-2085196689.1547608536",
        },
      },
      {
        name: "Ovolo Central",
        link: {
          target: "_blank",
          href:
            "https://ovolohotels.com.hk/ovolocentral?_ga=2.201158637.2106402864.1549718394-2085196689.1547608536",
        },
      },
      {
        name: "Mojo Nomad Aberdeen",
        image: "icons/logo-mojo.svg",
        link: {
          target: "_blank",
          href:
            "https://www.mojonomad.com/aberdeen?_ga=2.202329682.2106402864.1549718394-2085196689.1547608536",
        },
      },
      {
        name: "Mojo Nomad Central",
        link: {
          target: "_blank",
          href:
            "https://www.mojonomad.com/central/?_ga=2.21801976.2106402864.1549718394-2085196689.1547608536",
        },
      },
    ],
  },
  {
    name: "The Silver Egg",
    link: {
      href:
        "https://ovolohotels.com.au/?_ga=2.260924238.2106402864.1549718394-2085196689.1547608536",
    },
  },
  {
    name: "radio ovolo",
    link: {
      href:
        "https://ovolohotels.com.au/?_ga=2.260924238.2106402864.1549718394-2085196689.1547608536",
    },
  },
  {
    name: "Social",
    link: {
      href:
        "https://ovolohotels.com.au/?_ga=2.260924238.2106402864.1549718394-2085196689.1547608536",
    },
  },
  {
    name: "About",
    link: {
      href:
        "https://ovolohotels.com.au/?_ga=2.260924238.2106402864.1549718394-2085196689.1547608536",
    },
  },
  {
    name: "Development",
    link: {
      href:
        "https://ovolohotels.com.au/?_ga=2.260924238.2106402864.1549718394-2085196689.1547608536",
    },
  },
  {
    name: "Contact",
    link: {
      href:
        "https://ovolohotels.com.au/?_ga=2.260924238.2106402864.1549718394-2085196689.1547608536",
    },
  },
]
export const EXAMPLE_AWARD: IAward = {
  type: "TC_2018_KNOCKOUT_LL_TM",
  alt: "2019 TripAdvisor Travellers Choice",
  link: {
    href:
      "https://www.tripadvisor.com.au/Hotel_Review-g255060-d257469-Reviews-Ovolo_Woolloomooloo-Sydney_New_South_Wales.html",
    target: "_blank",
  },
}
export const FOOTER_NAVIGATION: INavigationItem[] = [
  {
    name: "Home",
    link: {
      href: "https://ovolohotels.com/",
    },
  },
  {
    name: "Offers",
    link: {
      href: "https://ovolohotels.com/offers/",
    },
  },
  {
    name: "Why book direct",
    link: {
      href: "https://ovolohotels.com/why-book-direct/",
    },
  },
  {
    name: "Privacy policy",
    link: {
      href: "https://ovolohotels.com/privacy-policy/",
    },
  },
  {
    name: "Silver Egg Blog",
    link: {
      href: "https://ovolohotels.com/thesilveregg/",
    },
  },
  {
    name: "Sitemap",
    link: {
      href: "https://ovolohotels.com/sitemap/",
    },
  },
]
