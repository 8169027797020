import React, { FunctionComponent, useEffect, useState } from "react"

import "./Callouts.scss"
import { window } from "browser-monads"
import classNames from "classnames"
import { isMobileOnly, isMobile } from "react-device-detect"

let isSmall = false
const hasWindow = typeof window !== `undefined` ? true : null
if (hasWindow) {
  if (window.innerWidth < 980) {
    isSmall = true
  }
}

export const Callouts: FunctionComponent<{}> = ({ children }) => {
  const [isLandscape, setIsLandscape] = useState<boolean>(
    window.innerHeight < window.innerWidth
  )
  useEffect(() => {
    window.onresize = () => {
      setIsLandscape(window.innerHeight < window.innerWidth)
    }
  }, [])
  return (
    <section className={classNames({ mobile: isSmall }, "callouts")}>
      <div className="callouts--inner">{children}</div>
    </section>
  )
}

export default Callouts
