import React, { FunctionComponent } from "react"

import "./Footer.scss"
import { isMobile } from "react-device-detect"
import classNames from "classnames"

let isSmall = false
const hasWindow = typeof window !== `undefined` ? true : null
if (hasWindow) {
  if (window.innerWidth < 980) {
    isSmall = true
  }
}

export const Footer: FunctionComponent<{}> = ({ children }) => (
  <footer className={classNames({ mobile: isMobile })}>{children}</footer>
)

export default Footer
