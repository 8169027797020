/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import "../index.scss"

import { Address } from "./Address"
import { Button } from "./Button"
import { Callouts } from "./Callouts"
import { CssGrid } from "./CssGrid"
import { Footer } from "./Footer"
import { GlobalMenu } from "./GlobalMenu"
import { Header } from "./Header"
import { Logo } from "./Logo"
import { Navigation } from "./Navigation"
import { Newsletter } from "./Newsletter"
import { Telephone } from "./Telephone"

import { NAVIGATION_FOOTER, NEWSLETTER_CONFIG } from "../constants"

const SITE = process.env.WP_URL == null ? "ovolo" : process.env.WP_URL

let NAVIGATION_ITEMS = {}
let LOGO_SRC = ""

import "./Content.scss"
import "./image-with-link.scss"
import "../Theme.scss"

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        allWordpressAcfOptions {
          edges {
            node {
              options {
                address
                telephone
                address_2
                telephone_2
                logo {
                  source_url
                }
              }
            }
          }
        }
        footer: allWordpressWpApiMenusMenusItems(
          filter: { slug: { eq: "footer" } }
        ) {
          edges {
            node {
              items {
                title
                object_slug
                url
                target
                classes
              }
            }
          }
        }
        header: allWordpressWpApiMenusMenusItems(
          filter: { slug: { eq: "header" } }
        ) {
          edges {
            node {
              items {
                title
                url
                target
                wordpress_children {
                  title
                  url
                  target
                  classes
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="theme">
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta name="theme-color" content="#80C391" />
          <meta name="format-detection" content="telephone=no" />
        </Helmet>
        {data.allWordpressAcfOptions.edges.map(({ node }) => {
          LOGO_SRC = node.options.logo.source_url
        })}

        {data.header.edges.map(({ node }, i) => {
          NAVIGATION_ITEMS = node.items
        })}

        <Header fixed={true}>
          <Logo
            link={{ href: "/", alt: "Ovolo Group" }}
            alt="Ovolo Group"
            src={LOGO_SRC}
            color="white"
          />
          <Navigation items={NAVIGATION_ITEMS} />
          <GlobalMenu
            items={[NAVIGATION_ITEMS[0], NAVIGATION_ITEMS[1]]}
            footer={NAVIGATION_FOOTER}
          />
        </Header>

        {children}

        <Footer>
          <Button
            className="back-to-top"
            trashhand={true}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Back to top
          </Button>
          <div className="middle-logo">
            <Logo
              link={{ href: "/", alt: "Ovolo Hotels" }}
              alt="Ovolo Hotels"
              src={LOGO_SRC}
              color="white"
            />
          </div>
          <Callouts>
            <div>
              {data.allWordpressAcfOptions.edges.map(({ node }) => (
                <Address
                  key={node.options.address_2}
                  value={node.options.address_2}
                />
              ))}

              {data.allWordpressAcfOptions.edges.map(({ node }) => (
                <Telephone
                  key={node.options.telephone_2}
                  value={node.options.telephone_2}
                />
              ))}
            </div>
            <Newsletter
              postUrl="https://newsletter.ovolohotels.com/home"
              title="OMG Newsletter Signup"
              config={NEWSLETTER_CONFIG}
            />
            <div>
              {data.allWordpressAcfOptions.edges.map(({ node }) => (
                <Address
                  key={node.options.address}
                  value={node.options.address}
                />
              ))}

              {data.allWordpressAcfOptions.edges.map(({ node }) => (
                <Telephone
                  key={node.options.telephone}
                  value={node.options.telephone}
                />
              ))}
            </div>
          </Callouts>
        </Footer>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cloud.typography.com/6306216/7119412/css/fonts.css"
        />
        <div id="modal-root" />
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
